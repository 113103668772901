// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/event/cross-industry/thank.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".thank-you-box[data-v-5ac79394] {\n  height: 100vh;\n  text-align: center;\n  background-color: #F5F5F5;\n  background-image: linear-gradient(0deg, rgba(223, 243, 248, 0.8), rgba(223, 243, 248, 0.8)), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.thank-you-content[data-v-5ac79394] {\n  font-size: 18px;\n  color: var(--text-light-color);\n}\n.thank-button-group[data-v-5ac79394] {\n  max-width: 322px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n}\n.m-bottom-5[data-v-5ac79394] {\n  margin-bottom: 20px;\n}\n.go-back-home-btn[data-v-5ac79394] {\n  font-size: 16px;\n  padding: 15px 0px;\n}\n", ""]);
// Exports
module.exports = exports;
